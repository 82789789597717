import styled, { keyframes } from "styled-components";

import React from "react";

const Input = styled.input`
  z-index: -1;
  opacity: 0;
  position: absolute;
  left: 0;
  pointer-events: none;

  &:checked + div {
    background: linear-gradient(-225deg, rgb(62, 41, 79) 0%, rgb(219, 110, 107) 100%);
    border: none;
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  font-family: 'Eina';
  font-size: 14px;
  letter-spacing: 0.2px;
  color: white;
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const Indicator = styled.div`
  width: 1.2em;
  height: 1.2em;
  background: transparent;
  position: absolute;
  top: 0em;
  left: 0em;
  border: 1px solid #a7a6b3;

  /* ${Input}:not(:disabled):checked & {
    background-color: #d1d1d1;
  } */

  /* ${Label}:hover & {
    background: #ccc;
  } */

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    
    top: 0.1em;
    left: 0.35em;
    width: 35%;
    height: 70%;
    border: solid white;
    border-width: 0 0.15em 0.15em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export default function Checkbox({
  value,
  checked,
  onChange,
  name,
  id,
  label,
  disabled,
  required,
}) {
  return (
    <Label htmlFor={id} disabled={disabled}>
      <span style={{marginLeft: '1.625rem'}}>{label}</span>
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        defaultChecked={checked}
        onChange={onChange}
        required={required}
      />
      <Indicator />
    </Label>
  );
}
